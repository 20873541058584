import React from "react"
import LazyLoad from "react-lazyload"
import Iframe from "react-iframe"
import styled from "styled-components"

interface YouTubeVideoProps {
  title: string
  url: string
  subNote?: string
}

const YouTubeWrapper = styled.div`
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

const YouTubeVideo = ({ title, url, subNote }: YouTubeVideoProps) => (
  <div>
    <LazyLoad once>
      <YouTubeWrapper
        tabIndex={0}
        title={title}
        className="overflow-hidden relative bg-lwb-grey-light"
      >
        <Iframe
          url={`${url}?rel=0`} // rel=0 - hide related videos
          allowFullScreen
          aria-label={title}
        />
      </YouTubeWrapper>
    </LazyLoad>
    {subNote && <div className="center tc">{subNote}</div>}
  </div>
)

export default YouTubeVideo
