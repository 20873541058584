import React from "react"
import classNames from "classnames"
import {
  ReadMoreLink,
  Section,
  Container,
  Row,
  Box
} from "@life-without-barriers/react-components"

import YouTubeVideo from "../YouTubeVideo"

export interface PinkVideoSectionProps {
  quote: string
  quoteName: string
  youtubeVideoTitle: string
  youtubeVideoUrl: string
  storyLink?: string
}

const PinkVideoSection = (props: PinkVideoSectionProps) => {
  const videoSectionClasses = classNames({
    "tc ph0 ph4-ns": true
  })
  return (
    <Section background="bg-lwb-theme-xxx-light">
      <Container>
        <Row>
          <Box className="w-100">
            <div className="copy lh-copy para tc center w-100">
              <p className="dib ma0 ts-display-3 color-lwb-theme-darker tc fw2">
                &quot;
                {props.quote}
                &quot;
              </p>
              <p className="ma0 tc fw7 pv4">- {props.quoteName}</p>
            </div>
            <div className={videoSectionClasses}>
              <YouTubeVideo
                title={`${props.youtubeVideoTitle}`}
                url={`${props.youtubeVideoUrl}`}
              />
              {props.storyLink && (
                <ReadMoreLink
                  href={`${props.storyLink}`}
                  text="Read Their Story"
                  className="mt4"
                />
              )}
            </div>
          </Box>
        </Row>
      </Container>
    </Section>
  )
}

export default PinkVideoSection
