import {
  Banner,
  Box,
  CardEmpty,
  Container,
  Heading,
  Helmet,
  IconAdoption,
  IconArrowRight,
  IconEmergency,
  IconGuardianship,
  IconKinship,
  IconLongTerm,
  IconRespite,
  IconShortTerm,
  Intro,
  LargeCTA,
  Link,
  PreStyledComponents,
  ReadMoreLink,
  Row,
  Section,
  Theme,
  Variables,
  routesObject
} from "@life-without-barriers/react-components"

import { BackgroundImageGradient } from "../../components/foster-care/LocalStyledComponents"
import { IGatsbyImageData } from "gatsby-plugin-image"
import Layout from "../../components/foster-care/Layout"
import PinkVideoSection from "../../components/foster-care/PinkVideoSection/PinkVideoSection"
import React from "react"
import { Site } from "../../contentTypes"
import { getGatsbyImage } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

interface IconAndLinkProps {
  iconType: string
  color: string
  to: string
  linkText: string
  className?: string
  iconBG?: string
}

const {
  youthPinkMedium,
  youthPinkXXXLight,
  fosterCareTypePurple,
  fosterCareGreen,
  fosterCareTypeGreenLight
} = Variables

const { IconWrapper, Container: ContainerNoGrid } = PreStyledComponents

const iconSelector = (type: string, color: string, height?: string) => {
  if (type === "respite") {
    return <IconRespite color={color} height={height} />
  }
  if (type === "emergency") {
    return <IconEmergency color={color} height={height} />
  }
  if (type === "shortterm") {
    return <IconShortTerm color={color} height={height} />
  }
  if (type === "longterm") {
    return <IconLongTerm color={color} height={height} />
  }
  if (type === "kinship") {
    return <IconKinship color={color} height={height} />
  }
  if (type === "guardianship") {
    return <IconGuardianship color={color} height={height} />
  }
  if (type === "adoption") {
    return <IconAdoption color={color} height={height} />
  }
  return undefined
}

const IconAndLink = ({
  iconType,
  color,
  to,
  linkText,
  className,
  iconBG
}: IconAndLinkProps) => (
  <div>
    <div className="dn-l">
      <div className={`flex items-center ${className ?? ""}`}>
        <IconWrapper color={iconBG} size="55">
          {iconSelector(iconType, color, "35")}
        </IconWrapper>
        <Link to={to} className="pl3 color-lwb-black">
          {linkText}
        </Link>
      </div>
    </div>
    <div className="dn db-l">
      <div className={`flex items-center ${className ?? ""}`}>
        <IconWrapper color={iconBG} size="80">
          {iconSelector(iconType, color, "40")}
        </IconWrapper>
        <Link to={to} className="pl3 color-lwb-black">
          {linkText}
        </Link>
      </div>
    </div>
  </div>
)

const { corporateThemeFull, fosterCareThemeFull, youthFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    headerImage: IGatsbyImageData
    aboutChildrenInCareImage: IGatsbyImageData
    site: Site
  }
}

const AboutFosterCarePage = ({
  location,
  data: {
    headerImage,
    site: { siteMetadata },
    aboutChildrenInCareImage
  }
}: Props) => {
  return (
    <Layout>
      <div>
        <Helmet
          title={`What is foster care? -  About | ${siteMetadata.title}`}
          description="We are always looking for new foster carers across Australia. Foster care is provided to children and young people who are unable to live with their own families."
          image={headerImage}
          siteUrl={siteMetadata.siteUrl}
          path={location.pathname}
        />
        <Banner
          title="About foster care"
          image={headerImage}
          breadcrumbs={[routesObject.fosterCareHome]}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <Intro
                text="There's no denying that children fill your home with energy and
                life, but sometimes, for various reasons, they need time away
                from their family to heal and grow. Foster care is about
                providing a safe and supportive home for these children. It
                might be for a night, a week, a year or until a child reaches
                adulthood. Our aim is always to reunite children with their
                family, but we know this is not always possible. With nearly
                50,000 children unable to live safely at home in Australia we
                are always looking for foster carers."
              />
              <Heading size={2} topMargin="standard">
                Types of foster care
              </Heading>
              <p>
                When it comes to fostering, no two children or families are the
                same. Some children may need foster care for a few nights, while
                others will need a more permanent home. That is why we offer a
                range of placement types to meet the unique needs of children in
                care.
              </p>
            </Box>
          </Row>
          <Row className="flex-wrap pv3">
            <Box className="w-100 w-50-m w-33-l mv3">
              <CardEmpty theme={corporateThemeFull} ribbon={true}>
                <div>
                  <IconAndLink
                    className="pb3 pl2 pl0-l pt2 pt0-l"
                    iconType="respite"
                    to="/foster-care/types-of-foster-care/respite-foster-care"
                    linkText="Respite"
                    color={fosterCareGreen}
                    iconBG={fosterCareTypeGreenLight}
                  />
                  <IconAndLink
                    className="pb3 pl2 pl0-l pt2 pt0-l"
                    iconType="emergency"
                    to="/foster-care/types-of-foster-care/emergency-foster-care"
                    linkText="Emergency or Crisis"
                    color={fosterCareGreen}
                    iconBG={fosterCareTypeGreenLight}
                  />
                  <IconAndLink
                    className="pb3 pl2 pl0-l pt2 pt0-l"
                    iconType="shortterm"
                    to="/foster-care/types-of-foster-care/short-term-foster-care"
                    linkText="Short-term"
                    color={fosterCareGreen}
                    iconBG={fosterCareTypeGreenLight}
                  />
                </div>
              </CardEmpty>
            </Box>
            <Box className="w-100 w-50-m w-33-l mv3">
              <CardEmpty theme={fosterCareThemeFull} ribbon={true}>
                <div>
                  <IconAndLink
                    className="pb3 pl2 pl0-l pt2 pt0-l"
                    iconType="longterm"
                    to="/foster-care/types-of-foster-care/long-term-foster-care"
                    linkText="Long Term"
                    color={youthPinkMedium}
                    iconBG={youthPinkXXXLight}
                  />
                  <IconAndLink
                    className="pb3 pl2 pl0-l pt2 pt0-l"
                    iconType="kinship"
                    to="/foster-care/types-of-foster-care/kinship-foster-care"
                    linkText="Kinship"
                    color={youthPinkMedium}
                    iconBG={youthPinkXXXLight}
                  />
                </div>
              </CardEmpty>
            </Box>
            <Box className="w-100 w-50-m w-33-l mv3">
              <CardEmpty theme={youthFull} ribbon={true}>
                <div>
                  <IconAndLink
                    className="pb3 pl2 pl0-l pt2 pt0-l"
                    iconType="guardianship"
                    to="/foster-care/types-of-foster-care/guardianship-foster-care"
                    linkText="Guardianship"
                    color={fosterCareTypePurple}
                    iconBG={youthPinkXXXLight}
                  />
                  <IconAndLink
                    className="pb3 pl2 pl0-l pt2 pt0-l"
                    iconType="adoption"
                    to="/foster-care/types-of-foster-care/foster-care-to-adoption"
                    linkText="Foster to Adoption"
                    color={fosterCareTypePurple}
                    iconBG={youthPinkXXXLight}
                  />
                </div>
              </CardEmpty>
            </Box>
          </Row>
          <Row>
            <Box>
              <ReadMoreLink
                href="/foster-care/types-of-foster-care"
                text="Learn more about types of care"
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <div className="ph3 ph4-ns">
            <ContainerNoGrid className="pv5 pv6-l relative overflow-hidden">
              <div className="w-100 w-60-l relative z-2">
                <h2 className="ts-display-3 ma0 fw8">About children in care</h2>
                <div className="copy mt4">
                  There are so many reasons why children come into foster care.
                  They come from all cultures and from all corners of our
                  country. While each child has a unique set of needs and
                  experiences, almost all come from complex family and social
                  situations where they may have suffered neglect, trauma or
                  abuse.
                </div>
                <Link
                  to="/foster-care/about-children-in-care"
                  className="color-lwb-theme-x-dark link db mt4"
                  role="link"
                >
                  Learn more about children in care
                  <IconArrowRight
                    height="8"
                    width="5"
                    color={youthPinkMedium}
                    className="ml2"
                  />
                </Link>
              </div>
              <div className="dn db-l absolute top-0 right-2 z-0">
                <BackgroundImageGradient
                  imgStyle={{
                    objectPosition: "top center"
                  }}
                  image={getGatsbyImage(aboutChildrenInCareImage)}
                  alt="Happy smiling toddler girl"
                />
              </div>
            </ContainerNoGrid>
          </div>
        </Section>
        <PinkVideoSection
          storyLink="/foster-care/stories/chris-and-sophiaan"
          quote="We took our time building a home environment that was safe, supportive and nurturing."
          quoteName="Chris and Sophiaan, foster carers in Queensland"
          youtubeVideoTitle="Chris and Sophiaan"
          youtubeVideoUrl="https://www.youtube.com/embed/9jWjsKHM_EA"
        />
        <Section background="bg-lwb-white">
          <Container>
            <LargeCTA
              headingText="Interested in attending a foster care info session?"
              contentBlock={{
                kind: "inline",
                elements: (
                  <p>
                    Life Without Barriers run virtual and in-person foster care
                    information sessions across Australia to help you get a
                    better idea of what it is all about. Sign up for the next
                    event in your state now!
                  </p>
                )
              }}
              ctaButton={{
                kind: "link",
                buttonText: "Attend an info session",
                to: "https://www.eventbrite.com.au/o/life-without-barriers-18159721409"
              }}
            />
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    headerImage: file(
      relativePath: { regex: "/about-foster-care-banner.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 1440)
      }
    }
    aboutChildrenInCareImage: file(
      relativePath: { regex: "/smiling-child.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, height: 600)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default AboutFosterCarePage
